import React, { Fragment } from 'react';
import { connect } from 'dva';
import { Input, Select, DatePicker, Table, Icon, message, Tooltip, Radio } from 'antd';

import TopBtn from 'components/TopBtn';
import OnlyPagination from 'components/OnlyPagination';
// import AdBanner from 'components/AdBanner';
import FilterSearch from './FilterSearch';
import FilterHeader from 'components/FilterHeader';
import FilterDrawer from 'components/FilterDrawer/FilterDrawer';
import InputGuide from 'components/FilterDrawer/InputGuide'

import classnames from 'classnames';
import moment from 'moment';
import intl from 'react-intl-universal';
import round from 'lodash/round';

import reactGaEvent from '../../utils/GAEvent';
import withTracker from '../../common/tracker.js';
import {
  getLanguage,
  getShopifyStoreStatus,
  fixedZero
} from '../../utils/utils';
import { getQueryUrlParams } from 'utils/global'
import { currencySymbolMap } from '../../common/sysconfig';

import orderStyles from './Order.less'

import styles from './Archived.less';
import './Archived.css';
import './Order.css';
import radioStyles from './FilterSearch/FilterSearch.less'

const { Option } = Select;
const { RangePicker } = DatePicker;

import timeZone from 'common/timezone';

@connect(({ order, loading, topBtn, login, global }) => ({
  order,
  loading: loading.models.order,
  userInfo: login.userInfo,
  topBtn,
  storeCurrency: global.storeCurrency,
  storeIdWithOrder: global.storeIdWithOrder,
  listLoading: loading.effects['order/archived'],
  unarchiveLoading: loading.effects['order/unarchive']
}))
@withTracker
export default class Archived extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: '',
      showDeleteSearch: false,
      searchFlag:false,
      drawerVisiable:false,
      dataType:[
        {
          type:1,
          timestamp:0,
          name:'Today'
        },
        {
          type:2,
          timestamp:7,
          name:'Last 7 days'
        },
        {
          type:3,
          timestamp:30,
          name:'Last 30 days'
        },
        {
          type:4,
          timestamp:90,
          name:'Last 90 days'
        },
        {
          type:5,
          timestamp:180,
          name:'Last 180 days'
        }
      ],
      dateRadioDefaultType:5,
      searchFlagBtn:false,
      hidden:true,
      istimeShow:false
    };

    this.cacheOrdersStoreId =
      localStorage.getItem('currentOrdersStoreId') || 'all';
  }

  cancelSDate;
  cancelEDate;

  componentDidMount() {


    if(window.location.href.includes('orderKey')){
      
      let orderKey = getQueryUrlParams(window.location.href, 'orderKey');
      let storeKey = getQueryUrlParams(window.location.href, 'storeId')

      this.setState({
        searchFlag:true
      })

      this.searchKey = orderKey
      this.setState({
        searchKey:orderKey,
      })

    }

    this.getStoreList(this.props.storeIdWithOrder);
    let isMove = true;
    var dom = document.getElementById("root");
    //按下标识
    let mouseFlag = false;

    dom.onclick = e => {
      let con = document.querySelector('#order_no_input_box')
      let con1 = document.querySelector('#filterText')
      let con2 = document.querySelector('#filterTextIcon')

      if(e.target.getAttribute('class') == 'ant-tooltip-open'){
        this.setState({
          searchFlag:true
        })
        return
      }
      if(e.target.getAttribute('id') == 'searchKey'){
        this.setState({
          searchFlag:true
        })
        return
      }

      if(typeof con?.contains === 'function'){
        if(e.target == con1 || e.target == con2){
          this.setState({
            searchFlag:true
          })
          return
        }
        if (!con?.contains(e.target) && isMove && !this.state.searchKey) {
          this.setState({
            searchFlag:false
          })
          //判断如果是不执行这个操作
          if(e.target.getAttribute('id') != 'filterText' && e.target.getAttribute('class') != 'filterBox'){
            setTimeout(()=>{
              this.setState({
                searchFlagBtn:false,
                hidden:true
              })
            },600)
          }
        }
      }
    }

    dom.onmousemove = e => {
      if(mouseFlag){
        isMove = false
      }
    }

    dom.onmousedown = (e) => {
      mouseFlag = true
    }

    dom.onmouseup = (e) => {
      mouseFlag = false
      setTimeout(()=>{
        isMove = true
      },800)
    }


    localStorage.setItem('cancelTime',JSON.stringify({
      Sdate: moment(moment().subtract(6, 'months')).format('YYYY-MM-DD'),
      Ddate: moment(moment()).format('YYYY-MM-DD'),
      Stime: moment()
      .subtract(6, 'months')
      .set({ hour: 0, minute: 0, second: 0 })
      .unix(),
      Dtime:moment()
      .set({ hour: 23, minute: 59, second: 59 })
      .unix()
    }))

    this.cancelSDate = moment(moment().subtract(6, 'months')).format('YYYY-MM-DD')
    this.cancelEDate = moment(moment()).format('YYYY-MM-DD')

  }

  componentDidUpdate(prevProps) {
    const { storeIdWithOrder } = this.props;
    if (storeIdWithOrder !== prevProps.storeIdWithOrder) {
      this.changeStore(storeIdWithOrder);
    }
  }

  currentLanguage = getLanguage();
  startDatetime = moment()
    .subtract(6, 'months')
    .set({ hour: 0, minute: 0, second: 0 })
    .unix();
  endDatetime = moment()
    .set({ hour: 23, minute: 59, second: 59 })
    .unix();
  currentPage = 1;
  searchType = 1;
  searchKey = '';
  currentPage = 1;
  pageSize = 40;

  // 获取商店列表
  getStoreList = id => {
    const stores = this.props.userInfo.stores || [];
    let oneStore = null;
    let oneStoreId = 'all';

    oneStore = stores.find(store => this.cacheOrdersStoreId === store.id);
    oneStoreId = oneStore ? oneStore.id : oneStoreId;

    localStorage.setItem('currentOrdersStoreId', oneStoreId);

    if(window.location.href.includes('orderKey')){
      let storeKey = getQueryUrlParams(window.location.href, 'storeId')
      this.props.dispatch({
        type: 'global/updateOrderStoreId',
        payload: storeKey
      });
    }else{
      if (oneStoreId !== id) {
        this.props.dispatch({
          type: 'global/updateOrderStoreId',
          payload: oneStoreId
        });
        return;
      }
    }


    this.getOrderList();
  };

  isSelectDate = false;

  // 获取order列表
  getOrderList = (flags = true) => {

    if(flags){
      const { dateRadioDefaultType, dataType } = this.state
      let dataObj = dataType.find(item => item.type == dateRadioDefaultType)
      let cancelData = JSON.parse(localStorage.getItem('cancelTime'))

      if(dateRadioDefaultType == 1){
        this.startDatetime = parseInt(moment().startOf('day').valueOf() / 1000)
        this.endDatetime = parseInt(moment().endOf('day').valueOf() / 1000)
      }
      if(dateRadioDefaultType == 2){
        this.startDatetime = parseInt(moment(cancelData?.Ddate).subtract(dataObj.timestamp, 'days').valueOf() / 1000)
        this.endDatetime = parseInt(moment(cancelData?.Ddate).valueOf() / 1000)
      }
      if(dateRadioDefaultType == 3){
        this.startDatetime = parseInt(moment(cancelData?.Ddate).subtract(dataObj.timestamp, 'days').valueOf() / 1000)
        this.endDatetime = parseInt(moment(cancelData?.Ddate).valueOf() / 1000)
      }
      if(dateRadioDefaultType == 4){
        this.startDatetime = parseInt(moment(cancelData?.Ddate).subtract(dataObj.timestamp, 'days').valueOf() / 1000)
        this.endDatetime = parseInt(moment(cancelData?.Ddate).valueOf() / 1000)
      }
      if(dateRadioDefaultType == 5){
        this.startDatetime = parseInt(moment(cancelData?.Ddate).subtract(dataObj.timestamp, 'days').valueOf() / 1000)
        this.endDatetime = parseInt(moment(cancelData?.Ddate).valueOf() / 1000)
      }
    }


    console.log('我执行了',this.startDatetime, this.endDatetime)

    this.props.dispatch({
      type: 'order/archived',
      payload: {
        data: {
          start_at: this.startDatetime,
          end_at: this.endDatetime,
          search_type: this.searchType,
          key_words: encodeURIComponent(this.searchKey),
          page_size: this.pageSize,
          current: this.currentPage
        },
        callback: d => {
          localStorage.removeItem('selectStore')
        }
      }
    });
  };

  // 更改搜索类型
  changeSearchType = v => {
  

    this.searchType = v;
    reactGaEvent({
      category: 'arcived',
      action: 'filterSearch',
      label: `${v}`
    });
  };

  // 删除搜索内容
  deleteSearchKey = () => {
    this.searchKey = '';
    this.setState({
      searchKey: ''
    });
    this.changeSearch();
  };

  // 输入搜索内容
  changeSearchKey = e => {
    this.searchKey = e.target.value;
    if (e.target.value) {
      this.setState({
        showDeleteSearch: true
      });
    }
    this.setState({
      searchKey: e.target.value
    });
  };

  // 鼠标经过搜索框是否展示删除图标
  showDeleteSearch = () => {
    // 鼠标经过搜索框是否展示删除图标
    if (this.searchKey) {
      this.setState({ showDeleteSearch: true });
    }
  };

  // 鼠标离开搜索框隐藏删除图标
  hideDeleteSearch = () => {
    this.setState({ showDeleteSearch: false });
  };

  // 开始搜索
  changeSearch = () => {
    this.currentPage = 1;

    // this.setState({
    //   searchFlag:false
    // })

    this.getOrderList();

    reactGaEvent({
      category: 'archived',
      action: 'search'
    });
  };

  // 改变商店
  changeStore = value => {
    window.ISFLAG = value;
    localStorage.setItem('selectStore', value);
    localStorage.setItem('currentOrdersStoreId', value);
    this.currentPage = 1;
    this.getOrderList();
  };

  // 筛选时间
  changeDatetime = m => {
    this.startDatetime = m[0].set({ hour: 0, minute: 0, second: 0 }).unix();
    this.endDatetime = m[1].set({ hour: 23, minute: 59, second: 59 }).unix();
    this.getOrderList();
  };

  // 搜索类型下拉
  searchMoreFilter = () => {
    return (
      <Select
        placeholder="Filter"
        dropdownMatchSelectWidth={false}
        style={{ width: 104 }}
        onChange={this.changeSearchType}
        defaultValue={1}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        suffixIcon={
          <img
            src={require('../../assets/newImportList/icon_expand_more-24px.png')}
          ></img>
        }
      >
        <Option value={1}>{intl.get('order.search_type.order_no')} </Option>
        <Option value={2}>{intl.get('order.search_type.customer_name')}</Option>
        <Option value={3}>{intl.get('order.search_type.email')}</Option>
        <Option value={4}>{intl.get('order.search_type.tracking_no')}</Option>
      </Select>
    );
  };

  // 改变一页显示数量
  changePageSize = v => {
    this.pageSize = parseInt(v);
    this.currentPage = 1;
    this.getOrderList();
  };

  // 改变页码
  changePager = flag => {
    this.currentPage =
      flag === 'prev' ? this.currentPage - 1 : this.currentPage + 1;
    this.getOrderList();
  };

  // un archive
  handleUnarchive = orderId => {
    this.props.dispatch({
      type: 'order/unarchive',
      payload: {
        data: {
          order_id: orderId
        },
        cancelMessage: true,
        callback: d => {
          if (d.code === 2000 || d.code === 2010) {
            message.success(intl.get('archeved.unarcheved_succ'));
            this.getOrderList();
          } else if (d.code === 5000 && d.msg === 'shopify archive') {
            message.error(intl.get('archeved.blank_tips'));
          } else {
            message.error(intl.get('archeved.unarcheved_fail'));
          }
        }
      }
    });
  };

  // 设置物流展示方式
  setTracking = tracking => {
    if (!tracking) {
      return '-';
    }

    const [a, ...b] = tracking.split(';');

    return (
      <Fragment>
        {a}&nbsp;
        {b.length ? (
          <Tooltip
            title={b.map(item => (
              <span>{item}</span>
            ))}
            overlayClassName={styles.lastTracking}
          >
            ...
          </Tooltip>
        ) : null}
      </Fragment>
    );
  };

  setRowValue = v => v || '-';

  // 表格行内容
  columns = () => {
    const { storeCurrency } = this.props;
    return [
      {
        title: intl.get('archeved.table_title_order'),
        dataIndex: 'order_number'
      },
      {
        title: intl.get('order.order_title.date'),
        dataIndex: 'date',
        width: 120,
        render: v => {
          const orderdate = new Date(v);
          return `${orderdate.getFullYear()}-${fixedZero(
            orderdate.getMonth() + 1
          )}-${fixedZero(orderdate.getDate())}`;
        }
      },
      {
        title: intl.get('archeved.customer'),
        dataIndex: 'customer',
        render: this.setRowValue
      },
      {
        title: intl.get('order.order_title.country'),
        dataIndex: 'country',
        render: this.setRowValue
      },
      {
        title: intl.get('archeved.email'),
        dataIndex: 'email',
        ellipsis: true,
        width: 180,
        render: this.setRowValue
      },
      {
        title: intl.get('setting.tab_tracking'),
        dataIndex: 'tracking',
        render: this.setTracking
      },
      {
        title: intl.get('order.order_title.income'),
        dataIndex: 'income',
        render: (v, record) => {
          return `${currencySymbolMap[record.currency || 'USD']} ${v / 100}`;
        }
      },
      {
        title: intl.get('order.order_product.cost'),
        dataIndex: 'cost',
        render: (v, record) => {
          let currencyCode;
          let currencySymbol;
          let isNotUSD;
          let currencyRates;

          if (storeCurrency[record.store_id]) {
            currencyCode =
              storeCurrency[record.store_id].currency_code || 'USD';
            currencySymbol = currencySymbolMap[currencyCode];
            isNotUSD = currencyCode !== 'USD';
            currencyRates = storeCurrency[record.store_id].rates_value;
          }
          return (
            <div className="last_cost">
              {`$ ${v / 100 || 0}`}
              {isNotUSD ? (
                <>
                  <br />
                  <span className={styles.currencyValue}>
                    {`${currencySymbol} ${round(
                      (v / 100 || 0) * currencyRates,
                      2
                    )}`}
                  </span>
                </>
              ) : null}
              <Tooltip title={intl.get('order.order_product.title_unarchive')}>
                <a
                  role="button"
                  className="unarchive"
                  tab-index="0"
                  onClick={() => this.handleUnarchive(record.order_id)}
                >
                  <i class="material-icons notranslate">undo</i>
                  {/* {intl.get('archeved.unarchive')} */}
                </a>
              </Tooltip>
            </div>
          );
        }
      }
    ];
  };

  // 没有订单数据
  noData = (
    <div className="order_onData">
      <div className="l">
        <img
          width="100"
          src={require('../../assets/newBing/ac-none.png')}
        ></img>
      </div>
      <div className="r">
        <div className="lwjuq">{intl.get('order.no_data.title')}</div>
        <div>
          <p className={styles.tip_left}>{intl.get('order.no_data.p4')}</p>
          <p className={styles.tip_left}>{intl.get('order.no_data.p1')}</p>
          <p className={styles.tip_left}>{intl.get('order.no_data.p2')}</p>
          <p className={styles.tip_left}>{intl.get('order.no_data.p3')}</p>
        </div>
      </div>
    </div>
  );

  searchFilterOrder = (type) => {


    if(type == 'search'){
      return <>
      <div className='inputBoxSearch'>
        <div className="filterBox" onClick={() => {
          this.setState({
            searchFlag:true,
            searchFlagBtn:true
          },() => {
            if (this.textInput) this.textInput.focus();
          })
          setTimeout(()=>{
            this.setState({
              hidden:false
            })
          },600)

        }}>
              <i id="filterTextIcon" class="material-icons notranslate">search</i>
              <span id="filterText" className='filterText'>{intl.get('order.search_type.searchOrder')}</span>
          
        </div>
        {
          this.state.searchFlag ? null : 
          <div className='inputBox'>
            <InputGuide />
          </div>
        }
        </div>
      </>
    }
    if(type == 'filter'){
      return <>
        <div className='filterBox' onClick={() => {
          this.setState({
            drawerVisiable:true,
          })
        }}>
          <i class="material-icons notranslate">filter_alt</i>
          <span className='filterText'>{intl.get('import_list.filter')}</span>
        </div>
      </>
    }
  }

  
  onChangeTime = (type, e) => {
    this.isSelectDate = true;
    if(type == 'startTime'){
      this.startDatetime = e.set({ hour: 0, minute: 0, second: 0 }).unix();
      this.cancelSDate = moment(e).format('YYYY-MM-DD')
    }
    if(type == 'endTime'){
      this.endDatetime = e.set({ hour: 23, minute: 59, second: 59 }).unix();
      this.cancelEDate = moment(e).format('YYYY-MM-DD')
    }
  }

  SDate = moment().subtract(6, 'months')
  Edate = moment()
  isTimeShow = false
  onChangeCustTime = (e) => {
    console.log(e.target.value)
    this.setState({
      dateRadioDefaultType: e.target.value
    })
  }

  getTimeDom = () => {
    // 本来一个RangePicker能搞定的  非得拆开俩个来写
    const { dataType, dateRadioDefaultType } = this.state

    const dateFormat = 'YYYY-M-DD';
    let startDatetime = moment(this.startDatetime * 1000).format('YYYY-MM-DD')
    let endDatetime = moment(this.endDatetime * 1000).format('YYYY-MM-DD')

    return <>
      <div className={classnames({
        [radioStyles.SearchBox]:true,
        [radioStyles.SearchBox_active]:true
      })} data-type="getTimeDom">
        <Radio.Group onChange={this.onChangeCustTime} defaultValue={dateRadioDefaultType}>
          {
            dataType.map(item => {
              return <Radio key={item.type} value={item.type}>{item.name}</Radio>
            })
          }
          <Radio key={9} value={6}>
            <div className={orderStyles.datePicker}>
              <div>Custom</div>
              <div className='DatePicker_box'>
                <p className='form'>From</p>
                <DatePicker
                  style={{width:'100%'}}
                  disabled={dateRadioDefaultType == 6 ? false : true} 
                  defaultValue={moment(startDatetime, dateFormat)} 
                  format={dateFormat}
                  onChange={e => this.onChangeTime('startTime', e)}
                />
                <span className='data_icon'>
                    <i
                      style={{ fontSize: 18, marginTop: '8px' }}
                      className="material-icons"
                    >
                      calendar_today
                    </i>
                  </span>
              </div>
              <div className='DatePicker_box'>
                <p className='form'>To</p>
                <DatePicker 
                  style={{width:'100%'}}
                  disabled={dateRadioDefaultType == 6 ? false : true} 
                  defaultValue={moment(endDatetime, dateFormat)} 
                  format={dateFormat}
                  onChange={e => this.onChangeTime('endTime', e)}
                />
                <span className='data_icon'>
                  <i
                    style={{ fontSize: 18, marginTop: '8px' }}
                    className="material-icons"
                  >
                    calendar_today
                  </i>
                </span>
              </div>
            </div>
          </Radio>
        </Radio.Group>
      </div>
    </>
  }

  onClearFilterComfig = () => {
    let Time = JSON.parse(localStorage.getItem('cancelTime'))
    this.cancelSDate = Time.Sdate
    this.cancelEDate = Time.Ddate
    this.startDatetime = Time.Stime
    this.endDatetime = Time.Dtime
    this.setState({
      drawerVisiable:false,
      dateRadioDefaultType:5
    })
    this.isTimeShow =false
    this.dateRadioDefaultType = 5
    this.getOrderList(false)
  }

  confirm = () => {
    this.getOrderList()
    this.dateRadioDefaultType = this.state.dateRadioDefaultType
    this.setState({
      drawerVisiable: false
    })
    this.isTimeShow = true
  }

  dateRadioDefaultType = 5;

  selectTag = () => {

    let TimeShow = this.cancelSDate && this.cancelEDate
    let Time = JSON.parse(localStorage.getItem('cancelTime'))

    // if(TimeShow){
    //   if(this.dateRadioDefaultType != 6){
    //     TimeShow = false
    //     return
    //   }
    //   if(this.cancelSDate == Time?.Sdate && this.cancelEDate == Time?.Ddate){
    //     TimeShow = false
    //   }else{
    //     TimeShow = true
    //   }
    // }
    // if(this.dateRadioDefaultType != 6){
    //   TimeShow = false
    // }else{
    //   TimeShow = true
    // }
    if(this.dateRadioDefaultType == 3||this.dateRadioDefaultType == 6||this.dateRadioDefaultType == 4||this.dateRadioDefaultType == 2||this.dateRadioDefaultType == 1){
      TimeShow = true
      this.setState({
        istimeShow:true
      })
    }else{
      if(this.dateRadioDefaultType == 5 && this.isTimeShow===true){
        TimeShow = true
      }else{
        TimeShow = false
      }
      
      // this.setState({
      //   istimeShow:false
      // })
    }

    return <div className="flagsTagList">
      {
        TimeShow ? <div  className="flagsTagListItem">
        <p onClick={() => {
          this.setState({
            drawerVisiable:true
          })
        }}>{moment(this?.startDatetime * 1000).format('YYYY-MM-DD')} ~ {moment(this?.endDatetime * 1000).format('YYYY-MM-DD')} </p>
        <div
          onClick={() => {
            this.setState({
              istimeShow:false

            })
            this.dateRadioDefaultType = 3;
            this.onClearFilterComfig()
          }}
        >
          <img src="https://img.dsers.com/images/order-flags-icon/close.png"></img>
        </div>
        </div> : null
      }
    </div>
  }

  inputDom = () => {
    const { searchKey, showDeleteSearch, hidden } = this.state;

    return <div className={hidden ? 'input_trastion' : 'input_trastion_active'}>  
      <div id="order_no_input_box">
        <div className={this.state.searchFlag ? 'order_no_input_box order_no_input_box_active' : 'order_no_input_box'}>
            <div className="ArchivedInputSearch">
              <div className="order_no_input_box_clearIcon">
                <div className='order_no_input_box_clearIcon_i_archived'>
                  {showDeleteSearch ? (
                    <Icon
                      type="close-circle"
                      theme="filled"
                      className={styles.deleteIco}
                      onClick={e => {
                        e.stopPropagation();
                        this.deleteSearchKey();
                      }}
                      onMouseEnter={e => {
                        e.stopPropagation();
                        this.showDeleteSearch();
                      }}
                    />
                  ) : null}
                </div>
              </div>      
              <Input
                addonBefore={this.searchMoreFilter()}
                className={styles.search}
                value={searchKey}
                placeholder={intl.get('order.search_input_placeholder')}
                onChange={this.changeSearchKey}
                ref={element => this.textInput = element}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    this.changeSearch();
                  }
                }}
                onMouseEnter={e => {
                  e.stopPropagation();
                  this.showDeleteSearch();
                }}
                onMouseLeave={e => {
                  e.stopPropagation();
                  this.hideDeleteSearch();
                }}
              />
              <button onClick={this.changeSearch}  style={this.state.searchFlag ? {display:'block'} : {display:'none'}}>
                <i class="material-icons notranslate">search</i>
              </button>
            </div>
          </div>
      </div>
    </div>
  }

  render() {
    const { listLoading, unarchiveLoading, order } = this.props;
    const {
      archivedData: { data = [], count = 0 }
    } = order;

    let FilterHeaderOptions = [
      {
        key:1,
        render: this.searchFilterOrder('search')
      },
      {
        key:2,
        render: this.searchFilterOrder('filter')
      },
    ]

    let DrawerOptions = [
      {
        title:intl.get('order.order_title.date'),
        key:0,
        filterComponent: this.getTimeDom()
      }
    ]

    const { searchKey, showDeleteSearch } = this.state;
    let listKeyIndex = 0; // 后台返回重复数据所以加个key

    let howToUseArchiveLink =
      'https://help.dsers.com/archive-order-menu-for-shopify/';
    if (this.currentLanguage === 'fr-FR') {
      howToUseArchiveLink =
        'https://help.dsers.com/archive-order-menu-for-shopify/';
    }
    if (this.currentLanguage === 'pt-PT') {
      howToUseArchiveLink =
        'https://help.dsers.com/archive-order-menu-for-shopify/';
    }
    return (
      <div className="archivedContent">
        {/* <AdBanner style={{ marginBottom: 20 }} /> */}
        <div style={{marginTop:40}}>
        <FilterHeader title={intl.get('archeved.title')} options={FilterHeaderOptions} renderInput={this.inputDom()} />
        </div>
        {/* <h3>{intl.get('archeved.title')}</h3> */}
        <p style={{marginTop:30}}>
          {/* {intl.getHTML('archeved.dec', {
            href: howToUseArchiveLink
          })} */}
          {intl.get('archeved.processed')}
        </p>

       
        {this.selectTag()}
        {/* <div className="time_box">
                <p className="date">{intl.get('archeved.date_title')}</p>
                <RangePicker
                  onChange={this.changeDatetime}
                  getCalendarContainer={trigger => trigger.parentNode}
                  format="YYYY-MM-DD"
                  placeholder={['Start Time', 'End Time']}
                  defaultValue={[moment().subtract(6, 'months'), moment()]}
                  className={styles.rangePicker}
                  allowClear={false}
                />
                <i
                  class="material-icons"
                  style={{ position: 'absolute', right: '55px' }}
                >
                  calendar_today
                </i>
              </div> */}

        <Table
          rowKey={record => `${record.order_id}${listKeyIndex++}`}
          pagination={false}
          loading={listLoading || unarchiveLoading}
          dataSource={data}
          columns={this.columns()}
          locale={{ emptyText: this.noData }}
          className={styles.archivedListTable}
        />

        <OnlyPagination
          len={data ? data.length : 0}
          pageSize={this.pageSize}
          currentPage={this.currentPage}
          changePageSize={this.changePageSize}
          changePager={this.changePager}
          count={count}
        />

        <FilterDrawer
          defaultActiveKey={[0]}
          destroyOnClose={true}
          onClose={() => {
            this.setState({
              drawerVisiable:false
            })
          }}
          visible={this.state.drawerVisiable} 
          title={intl.get('order.order_title.title')}
          options={DrawerOptions}
          onConfirm={()=>{
            this.confirm()
          }}
          onCancel={this.onClearFilterComfig}
        />
      </div>
    );
  }
}
